import React from 'react'
import { themeStyles, colors, presets, metrics } from '../utils/theme'
import { chunk, range } from 'lodash'
import Carousel from 'nuka-carousel'
import { FaStar, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import withConfig from '../wrappers/withConfig'
import { rhythm } from '../utils/typography'

const styles = {
  featuredImage: {
    maxWidth: '200px',
    width: '100%',
  },
  container: {
    width: '270px',
    minHeight: '230px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    padding: `0 ${rhythm(0.5)}`,
  },
  reviewRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: rhythm(3),
  },
  blankButton: {
    backgroundColor: 'transparent',
    border: 0,
  },
  starHolder: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: rhythm(1),
  },
  attribution: {
    marginTop: rhythm(1),
    color: colors.gold,
  },
  small: {
    display: 'block',
    [presets.Tablet]: {
      display: 'none',
    },
  },
  medium: {
    display: 'none',
    [presets.Tablet]: {
      display: 'block',
    },
    [presets.Desktop]: {
      display: 'none',
    }
  },
  biggest: {
    display: 'none',
    [presets.Desktop]: {
      display: 'block',
    }
  }
}

const reviewList = [{
  content: "Fake",
  author: "Abe Clark"
}]

class Reviews extends React.Component {

  getCarosel = (numberToDisplayInRow=3, style=styles.biggest ) => {
    const chunks = chunk(this.props.reviews || [], numberToDisplayInRow)
    const elements = chunks.map(chunkInstance => {
      const result = chunkInstance.map((review, index) =>
        (<div key={review.author + index} css={{ backgroundColor: colors.white, padding: rhythm(0.5), borderRadius: '5px', margin: `0 ${rhythm(0.5)}`}}>
          <div css={styles.container}>
            <div css={styles.starHolder}>
              {range(5).map(num => <FaStar key={num} size={25} color={colors.gold}/>)}
            </div>
            <div>
              {review.content}
            </div>
            <div css={{display: 'flex', flex: 1}}/>
            <p css={styles.attribution}>
              <span css={{ fontWeight: 500 }}>{review.author}</span>
            </p>
          </div>
        </div>
      ))
      return (
        <div key={chunkInstance[0].id} css={styles.reviewRow}>
          {result}
        </div>
      )
    })

    return (
      <div css={style} key={numberToDisplayInRow}>
        <Carousel
          heightMode='current'
          renderCenterLeftControls={({ previousSlide, currentSlide }) => (
            <React.Fragment>
              {currentSlide && currentSlide > 0 ?
                <button onClick={previousSlide} css={[styles.blankButton, { display: 'none', [presets.Mobile]: {display: 'block'} }]} ><FaChevronLeft size={45} color={colors.mediumGrey} /></button> :
                null
              }
            </React.Fragment>
          )}
          renderCenterRightControls={({ nextSlide, currentSlide }) => (
            <React.Fragment>
              {currentSlide < (this.props.reviews.length / numberToDisplayInRow) - 1 ?
                <button onClick={nextSlide} css={[styles.blankButton, { display: 'none', [presets.Mobile]: {display: 'block'} }]} ><FaChevronRight size={45} color={colors.mediumGrey} /></button> :
                null
              }
            </React.Fragment>
          )}
          renderBottomCenterControls={() => <span />}
        >
          {elements}
        </Carousel>
      </div>
    )
  }

  render() {
    // Show different number for each screen size
    const screenSize3 = this.getCarosel(3, styles.biggest)
    const screenSize2 = this.getCarosel(2, styles.medium)
    const screenSize1 = this.getCarosel(1, styles.small)

    return (
      <div css={{ backgroundColor: colors.brightBlue}}>
        <h2 css={{ textAlign: 'center', color: colors.white, margin: metrics.defaultMargin }}>
          Our customers love us.
        </h2>
        <div css={{...themeStyles.contentWidth, padding: '0 5px'}}>
          {screenSize1}
          {screenSize2}
          {screenSize3}
        </div>
      </div>
    )
  }
}

export default withConfig(Reviews)