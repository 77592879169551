import React from 'react'
import Carousel from 'nuka-carousel'
import _ from 'lodash'
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

const styles = {
  blankButton: {
    backgroundColor: 'transparent',
    border: 0,
  },
}

const ImageSlider = ({ project, data }) => {
  const {
    apt_vac,
    details,
    dust,
    hall_vac,
    hard,
    mop,
    office_vac,
    soft,
    hotel,
    ride,
    IMG_1800,
    IMG_1808,
    IMG_1815,
    IMG_1834,
    IMG_1842,
    IMG_1851,
    IMG_1855,
    IMG_1863,
    IMG_1953,
    IMG_1964,
    IMG_1968,
    IMG_1984,
   } = data

   const images = [
    hotel,
    ride,
    hard,
    soft,
    apt_vac,
    dust,
    details,
    hall_vac,
    mop,
    office_vac,
  ]

  const carpetImages = [
    hotel,
    ride,
    IMG_1800,
    IMG_1808,
    IMG_1815,
    IMG_1834,
    IMG_1842,
    IMG_1851,
    IMG_1855,
    IMG_1863,
    IMG_1953,
    IMG_1964,
    IMG_1968,
    IMG_1984,
    apt_vac,
    soft,
    hall_vac,
    mop,
    office_vac,
    hard,
  ]

  const activeImages = project === 'CARPET' ? carpetImages : images
  const elements = _.map(activeImages, (img, index) => <Img loading='eager' fluid={img.childImageSharp.fluid} alt='cleaning image' key={index} />)
  return (
    <Carousel
      autoplay
      withoutControls
      wrapAround
    >
      {elements}
    </Carousel>
  )
}


const ImageSliderWithQuery = (props) =>
  <StaticQuery
    query={graphql`
      query SliderQuery {
        apt_vac: file(relativePath: { eq: "slider/apt_vac.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        details: file(relativePath: { eq: "slider/details.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        dust: file(relativePath: { eq: "slider/dust.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        hall_vac: file(relativePath: { eq: "slider/hall_vac.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        hard: file(relativePath: { eq: "slider/hard.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        mop: file(relativePath: { eq: "slider/mop.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        office_vac: file(relativePath: { eq: "slider/office_vac.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        hotel: file(relativePath: { eq: "slider/hotel.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        ride: file(relativePath: { eq: "slider/ride.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        soft: file(relativePath: { eq: "slider/soft.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1800: file(relativePath: { eq: "slider/IMG_1800.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1808: file(relativePath: { eq: "slider/IMG_1808.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1815: file(relativePath: { eq: "slider/IMG_1815.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1834: file(relativePath: { eq: "slider/IMG_1834.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1842: file(relativePath: { eq: "slider/IMG_1842.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1851: file(relativePath: { eq: "slider/IMG_1851.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1855: file(relativePath: { eq: "slider/IMG_1855.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1863: file(relativePath: { eq: "slider/IMG_1863.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1953: file(relativePath: { eq: "slider/IMG_1953.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1964: file(relativePath: { eq: "slider/IMG_1964.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1968: file(relativePath: { eq: "slider/IMG_1968.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},
        IMG_1984: file(relativePath: { eq: "slider/IMG_1984.jpg"}) { childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }},

      }
    `}
    render={data => <ImageSlider {...props} data={data}/>}
  />

  export default ImageSliderWithQuery
